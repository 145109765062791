import { Modal, Offcanvas } from 'bootstrap';
import Cookie from 'js-cookie';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            unAuthActions: 'user/unAuthActions',
            activeProperty: 'search/activePropertyView',
            user: 'user/user'
        }),
        $_mixinMobileValue() {
            let isIPadPro = window.screen.height == 1366 && window.screen.width == 1024;
            if (this.$mq === 'mobile' || this.$mq === 'tablet' || /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || isIPadPro) {
                return true;
            } else {
                return false;
            }
        }
    },
    methods: {
        $_setActionLoadingStatus(type, listingKey, loading) {
            this.$store.dispatch('search/setActionLoadingStatus', {
                type,
                listingKey,
                loading
            });
        },
        $_dispatchAuthActions() {
            for (let i = 0; i < this.unAuthActions.length; i++) {
                let action = this.unAuthActions[i]?.action || null;
                let actionKey = this.unAuthActions[i]?.data?.listingKey || null;
                if (!action) return;

                // if action type search/toggleUserFavorite then match with internal_property_id
                // if match then dispatch action
                if (action === 'search/toggleUserFavorite') {
                    // check if its from ldp or interstitial
                    let hasActiveProperty = this.activeProperty?.internal_property_id || this.activeProperty?.listingKey || null;

                    // req sent from srp listing card
                    if (!hasActiveProperty) {
                        this.$_setActionLoadingStatus('fav-listing', actionKey, true);
                        this.$store
                            .dispatch(action, this.unAuthActions[i]?.data)
                            .then((res) => {
                                this.$_toastSuccess(res.message);
                            })
                            .finally(() => {
                                this.$_setActionLoadingStatus('fav-listing', actionKey, false);
                            });
                    }

                    if (hasActiveProperty) {
                        // req from ldp or interstitial
                        let favoriteListingComponent = this.$root.$refs?.favoriteListing;
                        favoriteListingComponent?.setLoading(true);
                        if (parseInt(actionKey) === this.activeProperty.internal_property_id || parseInt(actionKey) === this.activeProperty.listingKey) {
                            this.$store
                                .dispatch(this.unAuthActions[i]?.action, this.unAuthActions[i]?.data)
                                .then((res) => {
                                    favoriteListingComponent?.setFavorite(true);
                                    this.$_toastSuccess(res.message);
                                })
                                .finally(() => {
                                    favoriteListingComponent?.setLoading(false);
                                });
                        }
                    }
                }

                if (action === 'search/saveSearch') {
                    this.$_setActionLoadingStatus('save-search', null, true);
                    this.$store.dispatch(action, this.unAuthActions[i]?.data).then((res) => {
                        this.$_setActionLoadingStatus('save-search', null, false);
                        this.$_toastSuccess('Search saved successfully');
                    });
                }
                this.$store.dispatch('user/clearUnAuthAction', i);
            }
        },
        //determines whether the user is using a mobile device
        //optional parameter bool `forced` can automatically set isMobile to true
        $_isMobile(forced = false) {
            //can override and make mobile
            if (forced) {
                return true;
            }
            return this.$_mixinMobileValue;
        },

        $_setModalObject(modalID = null) {
            if (modalID) {
                if (document.getElementById(modalID)) {
                    return new Modal(document.getElementById(modalID));
                }
            }
        },

        $_setOffcanvasObject(canvasID = null) {
            if (canvasID) {
                if (document.getElementById(canvasID)) {
                    return new Offcanvas(document.getElementById(canvasID));
                }
            }
        },

        //listens if an element is removed via dom manipulation. Callback fires when the passed element has disappeared
        $_onDomRemove(element, callback) {
            const parent = element.parentNode;
            if (!parent) throw new Error('The node must already be attached');
            const obs = new MutationObserver((mutations) => {
                for (const mutation of mutations) {
                    for (const el of mutation.removedNodes) {
                        if (el === element) {
                            obs.disconnect();
                            callback();
                        }
                    }
                }
            });
            obs.observe(parent, {
                childList: true
            });
        },

        //forces all modals to re-render
        $_forceReRender() {
            let modalsBackdrops = document.getElementsByClassName('modal-backdrop');
            // remove every modal backdrop
            for (let i = 0; i < modalsBackdrops.length; i++) {
                document.body.removeChild(modalsBackdrops[i]);
            }
            this.$nextTick(() => {
                // Add the component back in
                this.$root.renderKey += 1;
            });
        },

        //pass in the id's of the modals as the parameters
        $_toggleModalFromAnother(oldModal = null, newModal = null) {
            if (oldModal && newModal) {
                let oldModObj = this.$_setModalObject(oldModal);
                let newModObj = this.$_setModalObject(newModal);
                if (oldModObj && newModObj) {
                    oldModObj.hide();
                    newModObj.show();
                }
            }
        },

        $_clearSearchFilters(priceFilters = false, additionalFilters = true) {
            if (priceFilters) {
                this.$store.dispatch('search/setSearchMinPrice', null);
                this.$store.dispatch('search/setSearchMaxPrice', null);
            }

            this.$store.dispatch('search/setSearchPublicRemarks', null);

            return this.$store.dispatch('search/setSearchFilters', {
                daysOnMarket: null,
                extendedCriteria: [],
                listingTypes: ['residential', 'commercial', 'land', 'multi_family'],
                maxHoa: null,
                propertyStatus: ['active'],
                maxLotSize: null,
                maxPrice: null,
                maxSqf: null,
                maxYearBuilt: null,
                minBathrooms: null,
                minBedrooms: null,
                minLotSize: null,
                minPrice: null,
                minSqf: null,
                minYearBuilt: null,
                openHouseNextXDays: null,
                hasMatterport: null
            });
        },

        //cleans up formData before submitting it via axios
        $_purgeEmptyValues(formData = null) {
            if (!formData.details.property_id) {
                delete formData.details.property_id;
            }
            if (!formData.details.external_id) {
                delete formData.details.external_id;
            }
            return formData;
        },

        $_generateWalkScoreBody(walkscore = null) {
            let body = null;
            if (walkscore) {
                let walkscoreNum = walkscore.walkscore ? walkscore.walkscore : 0;
                let bikescoreNum = walkscore.bike.score ? walkscore.bike.score : 0;
                let transitcoreNum = walkscore.transit.score ? walkscore.transit.score : 0;
                let bikescoreClass = bikescoreNum > 49 ? 'walkscore-score-positive' : '';
                let walkscoreClass = walkscoreNum > 49 ? 'walkscore-score-positive' : '';
                let transitcoreClass = transitcoreNum > 49 ? 'walkscore-score-positive' : '';
                let description = walkscore.commentary;
                body =
                    "<div class='panel walkscore-wrapper'><div class='panel-body'><div class='d-flex flex-md-row justify-content-between align-items-center'><h2>Neighborhood info</h2><p class='txt-medium mb-0 d-none d-md-inline-block'>Source: Walkscore</p></div>";
                body += '<hr>';
                body += "<div class='walkscore-grid row'>";
                body +=
                    walkscoreNum > 0
                        ? "<div class='walkscore-grid-panel col-md-3 col-xs-12'><i class='bi-person-circle'></i><p class='walkscore-score " +
                          walkscoreClass +
                          "'><strong>" +
                          walkscoreNum +
                          "</strong>/100</p> <p class='walkscore-credits'>Walk Score &reg;</p></div>"
                        : '';
                body +=
                    bikescoreNum > 0
                        ? "<div class='walkscore-grid-panel col-md-3 col-xs-12'><i class='bi-signpost-split'></i><p class='walkscore-score " +
                          bikescoreClass +
                          "'><strong>" +
                          bikescoreNum +
                          "</strong>/100</p>  <p class='walkscore-credits'>Bike Score &reg;</p></div>"
                        : '';
                body +=
                    transitcoreNum > 0
                        ? "<div class='walkscore-grid-panel col-md-3 col-xs-12'><i class='bi-bicycle'></i><p class='walkscore-score " +
                          transitcoreClass +
                          "'><strong>" +
                          transitcoreNum +
                          "</strong>/100</p> <p class='walkscore-credits'>Transit Score &reg;</p></div>"
                        : '';
                body += "<div class='text-center mt-5 col-12'><h4>Overview</h4>" + description + '</div>';
                body += '</div>';
                body += '</div></div>';
            }
            return body;
        },

        //sets city and state cookie if they aren't wildcard
        $_setCityStateCookie(city = '', state = '') {
            // Don't set cookie on wildcard city
            if (city == 'Wildcard' && state == 'CA') {
                return;
            }
            const propertyObj = {
                c_city: city,
                c_state: state
            };
            this.$store.dispatch('tracking/setPropertyParams', propertyObj);
            Cookie.set('c_city', city);
            Cookie.set('c_state', state);

            if (city && state) {
                this.$root.$_setURICookie(false, city.split(' ').join('-'), state);
            }
        },

        $_setPinAsViewed(id = null) {
            if (id) {
                this.$store.dispatch('tracking/setViewed', id);
                let pin = document.getElementById('marker-' + id);
                if (pin) {
                    pin.classList.add('viewed');
                }
            }
        },

        $_srpLoginRedirect() {
            if (!this.$_isAuth()) {
                if (document.getElementById('emailAgentModalInt')) {
                    let srpLink = this.$_getSrpSessionLink();
                    if (srpLink) {
                        window.location.href = srpLink;
                    }
                } else {
                    location.reload();
                }
            }
        },

        //one click contact agent --- should be extendable
        $_oneClickContactAgent(auth_user, property) {
            this.loading = true;
            let formData = {
                first_name: this.auth_user.first_name,
                last_name: this.auth_user.last_name,
                email: this.auth_user.email,
                phone_number: this.auth_user.phone_number,
                details: {
                    external_id: property.listingKey ? property.listingKey : null,
                    message: '',
                    city: property.city ? property.city : 'Wildcard',
                    state: property.stateOrProvince ? property.stateOrProvince : 'CA',
                    clientRole: 'Buyer'
                },
                ready_for_agent: true,
                confirm_terms: true,
                type: 'agent-direct'
            };
            //fill a message to agent if property is null (ie coming from saved Search)
            if (property == null) {
                formData.details.message = 'Client wants to contact an agent regarding their search ' + this.currentSearch;
            } else {
                formData.details.message = 'User is requesting information about ' + property.unstructuredAddress + ' ' + property.city + ' ' + property.stateOrProvince;
            }
            axios
                .post('forms/form-request', formData)
                .then((res) => {
                    if (res.data.success) {
                        this.$root.$_toastSuccess('Your request has been submitted! A member of our team will be in contact with you shortly.');
                        this.loading = false;
                        this.formErrors = {};
                        this.$_trackEvent('contact-agent', 'Contact Agent', 'clicked', 'Contact Agent', formData);
                        this.$_trackEvent('property-inquiry', 'Contact Agent', 'clicked', 'Contact Agent Inquiry', formData);
                        this.$_trackEvent('call-request', 'Contact Agent', 'clicked', 'Contact Agent Inquiry', formData);
                        this.closeModal();
                    }
                })
                .finally(() => (this.loading = false));
        },

        //this logic sets the srp link and retrieves it. This is if someone is doing actions on the interstitial
        $_setSrpLink(url) {
            localStorage.setItem('srpLink', url);
        },

        $_getSrpSessionLink() {
            return localStorage.getItem('srpLink');
        },

        $_isSuccess() {
            if (this.$_getParamValue('completed') !== null) {
                return true;
            }
            return false;
        },

        // async $_getGeoLocationNew() {
        //     let retObject = {
        //         city: Cookie.get("c_city"),
        //         state: Cookie.get("c_state"),
        //         accuracy: 99999,
        //     };
        //     if (
        //         retObject.city &&
        //         retObject.city !== "Wildcard" &&
        //         retObject.state
        //     ) {
        //         retObject.accuracy = 0;
        //         return retObject;
        //     }
        //     try {
        //         let res = await axios({
        //             url: "/geoip/getCity",
        //             method: "get",
        //             timeout: 5000,
        //         });
        //         if (res.data.data.location.accuracy_radius <= 100) {
        //             let isAmerica = res.data.data.country == "US";
        //             if (isAmerica) {
        //                 // Set the City State cookie if it's not set
        //                 let city = res.data.data.city;
        //                 let state = res.data.data.state;
        //                 this.$_setCityStateCookie(city, state);
        //                 return {
        //                     city: city,
        //                     state: state,
        //                     accuracy: res.data.data.location.accuracy_radius,
        //                 };
        //             }
        //         }
        //     } catch (err) {
        //         console.error(err);
        //     }
        //     return retObject;
        // },

        $_miniIcon() {
            let miniIcon = window.globalMiniIcon || '';
            if (miniIcon) {
                return miniIcon;
            } else {
                return '/assets/img/realty-logo-small.png';
            }
        },

        $_maskNumbers(number = '') {
            //gets rid of country codes and plus signs etc
            let numberOnly = number.replace(/[^\d]/g, '');
            //this formats the string
            return numberOnly;
        },

        $_openDisclaimerSubModal(lender = null) {
            let disclaimerHTML = this.$_formatDisclaimerModalHTML(lender);
            document.getElementById('emailLenderModal').querySelector('.btn-close').click();
            document.getElementById('lender-disclaimer').innerHTML = disclaimerHTML;
        },

        $_formatDisclaimerModalHTML(lender = null) {
            //front profile image and details
            let profile =
                "<div class='d-flex align-items-center'><img src='" +
                lender.image +
                "' alt='Lender Photo' class='me-4 rounded-circle' width='75' height='75'>" +
                "<div><p class='m-0'>" +
                lender.email +
                '</p>' +
                "<p class='m-0'><a href='tel:" +
                lender.phoneNumber +
                "'>" +
                lender.phoneNumber +
                '</a>' +
                '</p>' +
                "<p class='m-0'>" +
                lender.companyName +
                '</p>';
            //if nmls
            if (lender.nmls) {
                profile = profile + "<p class='m-0'> NMLS# " + lender.nmls + '</p></div></div>';
            }
            //equal housing lender image
            profile =
                profile + "<hr><div class='row d-flex justify-content-center'>" + "<img class='w-40 mt-2 mb-2' src='/assets/img/equal-housing-opportunity.svg' alt='equal housing lender'></img></div>";
            //lender disclaimer info
            if (lender.brokerImage) {
                profile = profile + "<hr><div class='row d-flex justify-content-center'>" + "<img class='w-40 mt-2 mb-2' src='" + lender.brokerImage + "' alt='equal housing lender'></img></div>";
            }
            if (lender.disclaimer) {
                profile = profile + "<h4 class='text-center'>Lender Disclaimer</h4><div class='text-center mt-4'>" + lender.disclaimer + '</div>';
            }
            //if they have a profile URL
            if (lender.application_url) {
                profile =
                    profile +
                    "<div class='row d-flex justify-content-center mt-2 mb-2'><a href='" +
                    lender.application_url +
                    "' class='btn btn-primary' target='_blank'>Get Pre-Approved!</a>" +
                    '</div>';
            }
            //gets them out of the modal nicely
            profile =
                profile +
                "<hr><div class='d-flex justify-content-center'><a data-bs-toggle='modal' data-bs-target='#emailLenderModal' data-bs-dismiss='modal'  class='nav-link pagination-link'> <i class='bi-arrow-left me-2'></i> Back </a></div>";
            return profile;
        },

        $_validateField(filled = false, title = 'this field') {
            if (!filled) {
                this.$_toastError('You must fill out ' + title);
                return false;
            }
            return true;
        },
        //copy of the php function
        $_ucwords(string = null) {
            if (string) {
                string = string.toLowerCase().replace(/\b[a-z]/g, function (letter) {
                    return letter.toUpperCase();
                });
            }
            return string;
        },
        $_stringToTitle(s) {
            if (!s?.length) return '';

            return s
                .replace(/[_-]+/g, ' ')
                .replace(/([a-z])([A-Z])/g, '$1 $2')
                .replace(/^\w/, (c) => c.toUpperCase())
                .trim();
        },
        $_stringToUnderscoreSlug(s) {
            if (!s?.length) return '';
            s = s.toLowerCase(); // Convert all characters to lowercase
            if (s.includes(' ')) {
                s = s.replace(/\s+/g, '_'); // Replace spaces with underscores
            }
            if (/[^a-z0-9_]/.test(s)) {
                s = s.replace(/[^a-z0-9_]/g, ''); // Remove all non-alphanumeric characters except _
            }
            return s;
        },

        $_loadPins() {
            let pins = localStorage.getItem('viewed');
            let currentPins = JSON.parse(pins);
            if (currentPins) {
                this.$store.dispatch('tracking/setViewedLocalStorage', currentPins);
            }
        },

        // returns a location string from cookies
        $_getAoiCityState() {
            const propertyParams = this.$store.state.tracking.currentData.propertyParams || {};
            let city = propertyParams.c_city;
            let state = propertyParams.c_state;
            if (!city || !state) {
                city = Cookie.get('c_city');
                state = Cookie.get('c_state');
            }
            if (!city || !state) {
                const locationParams = this.$store.state.tracking.currentData.city || {};
                if (locationParams) {
                    city = locationParams?.city;
                    state = locationParams?.state;
                }
            }
            let retObj = { city: city, state: state };
            if (city && city !== 'Wildcard') {
                return retObj;
            }
            return null;
        },

        //gets geographic location and returns an object with the closest city, state, and accuracy radius
        //in kilometres.
        $_getGeoLocation() {
            const propertyParams = this.$store.state.tracking.currentData.propertyParams || {};
            let city = propertyParams.c_city;
            let state = propertyParams.c_state;
            if (!city) {
                city = Cookie.get('c_city');
            }
            if (!state) {
                state = Cookie.get('c_state');
            }
            let returnObject = {};
            if (city == 'Wildcard' && state == 'CA') {
                return;
            }
            if (city && state) {
                //getting from cookie
                returnObject.city = city;
                returnObject.state = state;
                returnObject.accuracy = 0;
            }
            return returnObject;
        },

        //flashes a toast error message with passed parameter message
        $_toastError(message = 'Please correct all fields on the form to continue') {
            this.$toast.open({
                message: message,
                position: 'top-right',
                type: 'error'
            });
        },

        //flashes a toast success message with passed parameter message
        $_toastSuccess(message = 'Success!') {
            this.$toast.open({
                message: message,
                position: 'top-right'
            });
        },

        //stores logged in user attributes to form values
        //param formData object and userObject
        //returns formData obj
        $_storeUserToForm(formData, user) {
            if (!formData || !user) {
                return formData;
            }
            formData.first_name = user.first_name;
            formData.last_name = user.last_name;
            formData.phone_number = user.phone_number;
            formData.email = user.email;
            return formData;
        },

        $_splitName(splitName) {
            return splitName
                .split(/(?=[A-Z])/)
                .join(' ')
                .replace('sort_key', 'Sort By')
                .replace(/_/g, ' ');
        },

        $_splitValue(splitValue) {
            if (/_/g.test(splitValue)) {
                return splitValue.replace(/_/g, ' ');
            } else {
                return splitValue;
            }
        },

        $_parseValue(parseName = null, parseValue = null) {
            parseValue = this.$_splitValue(parseValue);
            let monetaryValues = ['AssociationFee'];
            let sqareFeetValues = ['MaxAcreage', 'MinAcreage'];
            let daysValues = ['CumulativeDaysOnMarket'];
            if (monetaryValues.includes(parseName)) {
                //right now its less than, make this smarter
                parseValue = ' < $' + parseValue;
            }
            if (sqareFeetValues.includes(parseName)) {
                parseValue = this.$_acreToSquareFeet(parseValue, false) + ' Acres';
            }
            if (daysValues.includes(parseName)) {
                //right now its less than, make this smarter
                parseValue = parseValue + ' days';
            }
            return parseValue;
        },

        $_checkValue: function (value) {
            if (!Array.isArray(value)) return value;
        },

        $_checkName: function (name) {
            if (!['IncludeClustersOrPins', 'fetchLdJson', 'SearchMapSwLong', 'SearchMapSwLat', 'SearchMapNeLong', 'SearchMapNeLat'].includes(name)) return name;
        },
        //converts value from acre to sqFeet depending on "toFeet's" truth
        //if toFeet is false, reverse the math.
        $_acreToSquareFeet(value, toFeet = true) {
            if (toFeet) {
                value = value * 43560;
            } else {
                value = value / 43560;
                value = Math.ceil(value * 100) / 100;
            }
            return value;
        },
        //adds a get paramter to the URI with passed name and value
        $_addGetParameter(name = null, value = null) {
            let appender = window.location.search ? '&' : '?';
            if (name && value) {
                if (!window.location.search.includes(name + '=')) {
                    let newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + window.location.search + appender + name + '=' + value;
                    window.history.pushState({ path: newurl }, '', newurl);
                }
            }
        },
        //removes the get parameter
        $_stripGetParameter(name = null) {
            if (window.location.search.includes(name + '=')) {
                let newSearch = this.removeParam(name, window.location.search);
                let newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + newSearch;
                window.history.pushState({ path: newurl }, '', newurl);
            }
        },

        $_getParametersAsObject() {
            let search = location.search.substring(1);
            if (search) {
                if (Object && typeof Object.fromEntries === 'function') {
                    return Object.fromEntries(new URLSearchParams(search));
                } else {
                    return this.entriesPolyFill(search);
                }
            }
        },

        //for Older Browsers that dont have polyfill, ie FB native old browser, IE11
        entriesPolyFill(search) {
            let result = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
                return key === '' ? value : decodeURIComponent(value);
            });
            return result;
        },

        $_getParamValue(passed_param = null) {
            let response = null;
            if (passed_param) {
                let urlParams = new URLSearchParams(window.location.search);
                let param = urlParams.get(passed_param);
                response = param;
            }
            return response;
        },

        //are we authenticated?
        $_isAuth() {
            return window.isAuthenticated;
            //return this.user?.id ? true : false;
        },

        $_getUser() {
            return this.user || null;
        },

        $_invalidInput(formData, invalidPhone = null, invalidEmail = null, requiredMessage = false, minMessage = 5, maxMessage = 500) {
            if (invalidPhone || invalidEmail) {
                return true;
            }
            if (requiredMessage.length < minMessage || requiredMessage.length > maxMessage) {
                return true;
            }
            if (formData.first_name && formData.last_name && formData.email && formData.phone_number && formData.details.city && formData.details.state) {
                //minimum level of data
                return false;
            }
            return true;
        },
        $_isValidEmail(email) {
            var emailRegex =
                /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
            return emailRegex.test(email);
        },

        //converts a Google API GeoLocation object to something more legible for Realty
        $_getConvertedGeoLocationObject(geoObj = null) {
            let data = {};
            if (geoObj) {
                data.address = geoObj.formatted_address;
                let addressFields = geoObj.address_components;
                if (addressFields) {
                    let titles = addressFields.map(function (obj) {
                        return obj.types;
                    });
                    for (let i = 0; i < titles.length; i++) {
                        // city
                        if (titles[i].includes('locality')) {
                            data.city = addressFields[i].long_name;
                            continue;
                        }
                        //state
                        if (titles[i].includes('administrative_area_level_1')) {
                            data.state = addressFields[i].short_name;
                            continue;
                        }
                        //zip
                        if (titles[i].includes('postal_code')) {
                            data.zip = addressFields[i].short_name;
                            continue;
                        }
                    }
                }
            }
            return data;
        },

        $_isZipCode(cityVariable = null) {
            if (cityVariable && /^\d+$/.test(cityVariable) && cityVariable.length <= 5) {
                return true;
            }
            return false;
        },

        $_getFiltersUri() {
            let params = new URLSearchParams(document.location.search.substring(1));
            let filterParams = params.get('f');
            if (filterParams) {
                return filterParams;
            }
            return null;
        },

        $_setURICookie(uriRaw = null, city = null, state = null) {
            if (uriRaw) {
                Cookie.set('c_srp_uri', uriRaw);
            } else if (city && state) {
                Cookie.set('c_srp_uri', this.$_getSrpLink(city, state));
            }
        },

        $_getSrpLink(city = null, state = null) {
            let path = null;
            if (city && state) {
                path = '/search/' + state + '/' + city;
            }
            return path;
        },

        $_srpCookieLink() {
            return Cookie.get('c_srp_uri');
        },

        $_getCookie(name = null) {
            console.log('getting cookie, name: ', name);
            return name ? Cookie.get(name) : null;
        },

        $_agentDisclaimer(broker = null, agent = null) {
            if (agent && !agent.stateDisclaimer) {
                let brokerName = broker.stateBrokerName ? broker.stateBrokerName : '-';
                let licenseName = broker.stateLicense ? broker.stateLicense : '-';
                let firmName = broker.firm ? broker.firm : '-';
                let stateFirmLicense = broker.stateFirmLicense ? broker.stateFirmLicense : '-';
                let brokerAddress = broker.address ? broker.address : '-';
                return brokerName + ' License, ' + licenseName + ' corp@realty.com, 877-673-2589 ' + firmName + ' Firm License ' + stateFirmLicense + ', ' + brokerAddress;
            } else {
                if (agent && agent.stateDisclaimer) {
                    return agent.stateDisclaimer;
                } else {
                    return '-';
                }
            }
        },

        $_expireActiveCookies(cookieName) {
            let path = window.location.pathname;
            let paths = ['/'],
                pathLength = 1,
                nextSlashPosition;
            while ((nextSlashPosition = path.indexOf('/', pathLength)) != -1) {
                pathLength = nextSlashPosition + 1;
                paths.push(path.substr(0, pathLength));
            }

            for (let path of paths) document.cookie = `${cookieName}=; path=${path}; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
        },
        $_formatNumber(value) {
            // check if value  more then 1 million
            if (value > 1000000) {
                let formatter = Intl.NumberFormat('en', {
                    notation: 'compact',
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0
                });
                return formatter.format(value);
            } else {
                let formatter = Intl.NumberFormat('en', { notation: 'compact', style: 'currency', currency: 'USD' });
                return formatter.format(value);
            }
        },
        //straight off of stack overflow to strip a search of a parameter
        removeParam(key, sourceURL) {
            let rtn = sourceURL.split('?')[0],
                param,
                params_arr = [],
                queryString = sourceURL.indexOf('?') !== -1 ? sourceURL.split('?')[1] : '';
            if (queryString !== '') {
                params_arr = queryString.split('&');
                for (let i = params_arr.length - 1; i >= 0; i -= 1) {
                    param = params_arr[i].split('=')[0];
                    if (param === key) {
                        params_arr.splice(i, 1);
                    }
                }
                if (params_arr.length) rtn = rtn + '?' + params_arr.join('&');
            }
            return rtn;
        },

        $_isEmpty: (obj) => [Object, Array].includes((obj || {}).constructor) && !Object.entries(obj || {}).length,
        $_pickBy(object) {
            const obj = {};
            for (const key in object) {
                if (object[key] !== null && object[key] !== false && object[key] !== undefined) {
                    obj[key] = object[key];
                }
            }
            return obj;
        },
        $_get: (obj, path, defaultValue) => {
            const result = String.prototype.split
                .call(path, /[,[\].]+?/)
                .filter(Boolean)
                .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj);
            return result === undefined || result === obj ? defaultValue : result;
        },
        $_openInterstitial(property) {
            this.$_setPinAsViewed(property.listingKey);
            this.$store.commit('search/setInterstitialState', {
                id: property.listingKey,
                show: true,
                isFavorite: property.isFav,
                property: property
            });
        },
        $_setPixelOrPercent(number) {
            if (number) {
                if (number.toString().indexOf('%') > -1) {
                    return number;
                } else {
                    let pureNumber = parseInt(number);
                    return `${pureNumber}px`;
                }
            }
        },
        $_tenantName() {
            return window.companyName;
        },
        $_mlsName() {
            if (window.companyName == 'Realty.ca') {
                return 'MLS®';
            }
            return 'MLS';
        },

        updateMetaContent(nameType, nameValue, content, append) {
            const meta = document.querySelector(`meta[${nameType}="${nameValue}"]`);
            if (meta) {
                const existingContent = meta.getAttribute('content') || '';
                const newContent = append ? `${content} ${existingContent}` : content;
                meta.setAttribute('content', newContent);
            }
        },

        $_updateSeoDescription(description, append) {
            const tags = [
                { type: 'name', value: 'description' },
                { type: 'property', value: 'og:description' },
                { type: 'name', value: 'twitter:description' }
            ];

            tags.forEach((tag) => {
                this.updateMetaContent(tag.type, tag.value, description, append);
            });
        },

        $_updateSeoTitle(title, append) {
            document.title = title;
            const tags = [
                { type: 'name', value: 'title' },
                { type: 'property', value: 'og:title' },
                { type: 'name', value: 'twitter:title' }
            ];
            tags.forEach((tag) => {
                this.updateMetaContent(tag.type, tag.value, title, append);
            });
        },
        $_pluralize(val, word, plural = word + 's') {
            const _pluralize = (num, word, plural = word + 's') => ([1, -1].includes(Number(num)) ? word : plural);
            if (typeof val === 'object') return (num, word) => _pluralize(num, word, val[word]);
            return _pluralize(val, word, plural);
        }
    }
};
