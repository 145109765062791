import Cookie from 'js-cookie';

export default {
    setBrandingId(state, brandingId) {
        state.brandingInfo.branding_id = brandingId;
    },

    setInitialBrandingId(state, brandingId) {
        if (state.brandingInfo.initial_branding_id) {
            return state.brandingInfo.initial_branding_id;
        }
        state.brandingInfo.initial_branding_id = brandingId;
    },

    setBrandingGroupId(state, groupId) {
        state.brandingInfo.branding_group_id = groupId;
    },

    setInitialBrandGroup(state, initialGroupId) {
        if (state.brandingInfo.initial_group_id) {
            return state.brandingInfo.initial_group_id;
        }
        state.brandingInfo.initial_group_id = initialGroupId;
    },

    setBrandingLocationId(state, locationId) {
        state.brandingInfo.branding_location_id = locationId;
    },

    setInitialLocationId(state, locationId) {
        if (state.brandingInfo.initial_location_id) {
            return state.brandingInfo.initial_location_id;
        }
        state.brandingInfo.initial_location_id = locationId;
    },

    setLocationData(state, location) {
        state.locationData = location;
    },
    setLocationDistance(state, distance) {
        state.locationDistance = distance;
    },

    setEntranceData(state, data) {
        if (state.entranceData.timeStamp != null) {
            return state.entranceData;
        }
        state.entranceData = {
            ...state.entranceData,
            ...data
        };
        Cookie.set('entranceData', { brand: state.entranceData.brand });
    },
    setInterToEntranceData(state, data) {
        state.entranceData.brand = {
            ...state.entranceData.brand,
            ...data
        };
        Cookie.set(['entranceData'], { brand: state.entranceData.brand });
        Cookie.set('current_cookie', { brand: state.entranceData.brand });
    },
    setCurrentData(state, data) {
        state.currentData = {
            ...state.currentData,
            ...data
        };
    },
    setIsProgressive(state, isProgressive) {
        state.isProgressive = isProgressive;
    },
    setIsExtendedReg(state, isExtendedReg) {
        state.isExtendedReg = isExtendedReg;
    },
    setIsRentalFulfillment(state, isRentalFulfillment) {
        state.isRentalFulfillment = isRentalFulfillment;
    },
    setForced(state, data) {
        state.forced = data;
    },
    setForcedExists(state, data) {
        state.forcedExists = data;
    },
    setUtmParams(state, data) {
        state.entranceData.utmParams = {
            ...state.entranceData.utmParams,
            ...data
        };
        state.currentData.utmParams = {
            ...state.entranceData.utmParams,
            ...data
        };
    },
    setPropertyParams(state, data) {
        if (!state.entranceData.propertyParams.c_city) {
            state.entranceData.propertyParams = {
                ...state.entranceData.propertyParams,
                ...data
            };
        }
        state.propertyParams = {
            ...state.propertyParams,
            ...data
        };
    },
    setViewed(state, val) {
        //make it so it doesn't store the same values.
        const internal = val.toString();
        if (!state.viewed.includes(internal)) {
            if (!Array.isArray(state.viewed)) {
                state.viewed = [];
            }
            state.viewed.push(internal);
            if (state.viewed.length >= 20) {
                //remove the first viewed thing from this array. we don't want it to be too big
                state.viewed.shift();
            }
            localStorage.setItem('viewed', JSON.stringify(state.viewed));
        }
    },
    setViewedLocalStorage(state, val) {
        if (val && val.length > 0) {
            state.viewed = val;
        }
    },
    setIsRegistered(state) {
        state.isRegistered = true;
    },
};
