export default {
    setBrandingId(context, value) {
        context.commit('setBrandingId', value);
    },

    setInitialBrandingId(context, value) {
        context.commit('setInitialBrandingId', value);
    },
    setBrandingGroupId(context, value) {
        context.commit('setBrandingGroupId', value);
    },

    setInitialBrandGroup(context, value) {
        context.commit('setInitialBrandGroup', value);
    },

    setBrandingLocationId(context, value) {
        context.commit('setBrandingLocationId', value);
    },

    setInitialLocationId(context, value) {
        context.commit('setInitialLocationId', value);
    },

    setEntranceData(context, value) {
        context.commit('setEntranceData', value);
    },
    setInterToEntranceData(context, value) {
        context.commit('setInterToEntranceData', value);
    },

    setCurrentData(context, value) {
        context.commit('setCurrentData', value);
    },

    setIsProgressive(context, value) {
        context.commit('setIsProgressive', value);
    },

    setIsExtendedReg(context, value) {
        context.commit('setIsExtendedReg', value);
    },

    setIsRentalFulfillment(context, value) {
        context.commit('setIsRentalFulfillment', value);
    },

    setForced(context, value) {
        context.commit('setForced', value);
    },
    setViewed({ commit }, value) {
        commit('setViewed', value);
    },
    setViewedLocalStorage({ commit }, value) {
        commit('setViewedLocalStorage', value);
    },
    setUtmParams({ commit }, value) {
        const urlObject = value;
        const UTM_params = ['utm_campaign', 'utm_content', 'utm_term', 'utm_source', 'twclid', 'gclid', 'fbclid', 'utm_medium', 'forced-reg', 'gbraid', 'gad_source'];
        const UTM_params_obj = {};
        UTM_params.forEach((param) => {
            if (urlObject[param]) {
                UTM_params_obj[param] = urlObject[param];
            }
        });

        if (Object.keys(UTM_params_obj).length === 0) {
            return;
        }

        commit('setUtmParams', UTM_params_obj);
    },
    setPropertyParams({ commit }, value) {
        const urlObject = value;
        const propertyParams = ['c_property_listing_id', 'c_property_id', 'c_external_id', 'c_zip', 'c_city', 'c_state', 'c_zip', 'c_street'];
        const propertyParams_obj = {};

        propertyParams.forEach((param) => {
            if (urlObject[param]) {
                propertyParams_obj[param] = urlObject[param];
            }
        });

        if (Object.keys(propertyParams_obj).length === 0) {
            return;
        }

        commit('setPropertyParams', propertyParams_obj);
    },
    setForcedExists({ commit }, payload) {
        commit('setForcedExists', payload);
    },
    setIsRegistered({ commit }) {
        commit('setIsRegistered');
    },

};
