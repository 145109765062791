import './bootstrap';
import Vue from 'vue';
import Vuex, { mapState } from 'vuex';
import VueShortKey from 'vue-shortkey';
import 'promise-polyfill/src/polyfill';
import LazyLoad from 'vanilla-lazyload';
import VueMq from 'vue-mq';
import Sticky from 'sticky-js';
import VueToast from 'vue-toast-notification';
import FloatingVue from 'floating-vue';
import * as Sentry from '@sentry/vue';

import ScrollAnchorNavigation from './mixins/ScrollAnchorNavigation'; // Scroll anchor navigation and highlighting
// import VueTheMask from 'vue-the-mask';

// Mixins
// lotta barton bullshit
import HelpersMixin from './mixins/HelpersMixin'; // Helpers Mixin
import TagTrackerMixin from './mixins/TagTrackerMixin'; // Helpers Mixin
import config from './config';
import store from './store';
// const jitsu = jitsuClient({
//    key: window.jitsuKey,
//    tracking_host: 'https://events.realty.com',
//    randomize_url: true,
//    ga_hook: true
// });

// Vue.prototype.$jitsu = jitsu;
// filters
import './filters';
import './components';

window.Vue = Vue;
window.Vuex = Vuex;
Vue.use(Vuex);
Vue.use(FloatingVue, {
    themes: {
        'info-tooltip': {
            $extend: 'tooltip',
            $resetCss: true
        }
    }
});

window.Sentry = Sentry;

Sentry.init({
    Vue,
    dsn: 'https://846e05256511470b833b1968447c680a@o109532.ingest.sentry.io/4505160482029568',
    integrations: [
        new Sentry.BrowserTracing({
            integrations: [new Sentry.BrowserTracing()]
        })
        //new Sentry.Replay()
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    //tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    //replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
});

// Vue.use(VueTheMask);
Vue.use(VueToast);
Vue.use(VueShortKey, { prevent: ['input', 'textarea'] });
Vue.use(VueMq, {
    breakpoints: {
        mobile: 450,
        tablet: 900,
        laptop: 1250,
        desktop: Infinity
    }
});

const myLazyLoad = new LazyLoad({
    // Other options here...
    elements_selector: '.lazy',
    callback_error: (img) => {
        img.setAttribute('src', '/static_map.png');
    },
    class_loaded: 'lazy-loaded',
    load_delay: 500, // adjust according to use case
    threshold: 100 // adjust according to use case,
});

Vue.prototype.$searchSettings = window.searchSettings;
Vue.prototype.$lazyLoad = myLazyLoad;
Vue.prototype.$config = config;
// mixins
Vue.mixin(TagTrackerMixin);

// import globalComponents from './components';

// add vue dircetives
import mark from './directives/Mark';
import collapse from './directives/Collapse';
Vue.directive('mark', mark);
Vue.directive('collapse', collapse);


const v = new Vue({
    el: '#app',
    store,
    mixins: [ScrollAnchorNavigation, HelpersMixin],
    // components: globalComponents,
    data() {
        return {
            renderKey: 0,
            companyName: companyName || 'Realty.com',
            prependUrl: brandPrependUrl || null,
            formFreeDisplay: formfree_display || false,
            components: {
                mapbox: false,
                shareListing: false,
                lightgallery: false,
                streetview: false
            }
        };
    },
    computed: mapState({
        activeFilterOverlay: (state) => state.search.activeFilterOverlay
    }),
    mounted() {
        // sticky elements that fix
        // position during scroll
        this.$lazyLoad.update();
        //        this.$jitsu.track('pageview', {
        //            category: 'viewed'
        //        });

        const sticky = new Sticky('.sticky');
        sticky.update();
        // This initializes the scrollAnchorNav if there is an vailable content anchor element
        const contentAnchorNav = document.getElementById('content-anchor-nav');
        if (contentAnchorNav) {
            this.initializeScrollTargets('#content-anchor-nav a', '.scroll-target');
        }
        if (this.$root.$_isAuth()) {
            const user = this.$root.$_getUser();
            if (user) {
                Sentry.setUser({ email: user.email });
            }
            setTimeout(() => {
                this.$root.$_dispatchAuthActions();
            }, 1000);
        }

    }
});

// clear vuex user state if user is not logged in
if (!v.$root.$_isAuth()) {
    v.$store.commit('user/setUser', {});
    // Cookies.remove('realty_login', { path: '/' });
}
