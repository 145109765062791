export default {
  setUser(state, value) {
    state.user = value;
  },
  setLoading(state, value) {
    state.isLoading = value;
  },
  setLoggedIn(state, value) {
    state.isLoggedIn = value;
  },
  setSavedSearches(state, value) {
    state.savedSearches = value;
  },
  setSavedSearchLoading(state, value) {
    state.isSavedSearchLoading = value;
  },
  setViewedPropertiesLoading(state, value) {
    state.isViewedPropertiesLoading = value;
  },
  setViewedProperties(state, value) {
    state.viewedProperties = value;
  },
  setSavedPropertiesLoading(state, value) {
    state.isSavedPropertiesLoading = value;
  },
  setSavedProperties(state, value) {
    state.savedProperties = value;
  },
  setColorMode(state, value) {
    state.colorMode = value;
  },
  setGeoIPCity(state, value) {
    state.geoIPCity = value;
  },
  setViewedPropertyToSaved(state, listingKey) {
    // viewedProperties set user_type to saved
    state.viewedProperties = state.viewedProperties.map((property) => {
      if (property.listingKey === listingKey) {
        property.user_type = 'saved';
      }
      return property;
    });
    // move property from viewedProperties to savedProperties
    const property = state.viewedProperties.find((property) => property.listingKey === listingKey);
    state.savedProperties.unshift(property);
    // remove property from viewedProperties
    state.viewedProperties = state.viewedProperties.filter((property) => property.listingKey !== listingKey);
  },
  removeListingFromSavedList(state, listingKey) {
    // remove listing from saved list
    state.savedProperties = state.savedProperties.filter((property) => property.listingKey !== listingKey);
  },
  removeSearchFromSavedSearches(state, listingKey) {
    // remove listing from saved search
    state.savedSearches = state.savedSearches.filter((property) => property.id !== listingKey);
  },
  setPropertyDownPaymentResult(state, payload) {
    // use payload.key as key to avoid duplicates
    // remove existing result with same key
    state.propertyDownPaymentResults = state.propertyDownPaymentResults.filter((result) => result.key !== payload.key);
    // add new result
    state.propertyDownPaymentResults = [...state.propertyDownPaymentResults, payload];
  },
  setDemoAgentData(state, payload) {
    state.demoAgentData = payload;
  },
  setDemoLenderData(state, payload) {
    state.demoLenderData = payload;
  },
  setUnAuthActions(state, payload) {
    state.unAuthActions = payload;
  }

};
