import { login, register, progressiveRegistration, passwordReset, fetchSavedSearches, fetchSavedProperties, fetchViewedProperties, contactAgent, updateUser } from '../../../api/user';
import { formRequest } from '@/api/sell';

export default {
    async login({ commit }, credentials) {
        try {
            commit('setLoading', true);
            let res = await login(credentials);
            commit('setUser', res.data);
            commit('tracking/setForced', false, { root: true });
            // commit('setLoggedIn', true);
            return res;
        } catch (error) {
            throw error;
        } finally {
            commit('setLoading', false);
        }
    },
    async formRequest({ commit }, payload) {
        try {
            let res = await formRequest(payload);
            return res;
        } catch (error) {
            throw error;
        }
    },
    async register({ commit }, credentials) {
        try {
            commit('setLoading', true);
            let res = await register(credentials);
            commit('tracking/setForced', false, { root: true });
            commit('setUser', res.data);
            return res;
        } catch (error) {
            throw error;
        } finally {
            commit('setLoading', false);
        }
    },
    async progressiveRegister({ commit }, credentials) {
        try {
            commit('setLoading', true);
            let res = await progressiveRegistration(credentials);
            // commit('tracking/setForced', false, { root: true });
            commit('setUser', res.data);
            return res;
        } catch (error) {
            throw error;
        } finally {
            commit('setLoading', false);
        }
    },
    async passwordReset({ commit }, email) {
        try {
            commit('setLoading', true);
            let res = await passwordReset(email);
            return res;
        } catch (error) {
            throw error;
        } finally {
            commit('setLoading', false);
        }
    },
    async updateProfile({ commit }, payload) {
        try {
            commit('setLoading', true);
            return await updateUser(payload);
        } catch (error) {
            throw error;
        } finally {
            commit('setLoading', false);
        }
    },

    async getSavedSearches({ commit }, payload = {}) {
        try {
            commit('setSavedSearchLoading', true);
            const res = await fetchSavedSearches(payload);
            if (res.data) {
                commit('setSavedSearches', res.data.data);
            }
        } catch (error) {
            throw error;
        } finally {
            commit('setSavedSearchLoading', false);
        }
    },

    async getViewedProperties({ commit }, payload = {}) {
        try {
            commit('setViewedPropertiesLoading', true);
            const res = await fetchViewedProperties(payload);
            if (res.data) {
                commit('setViewedProperties', res.data);
            }
        } catch (error) {
            throw error;
        } finally {
            commit('setViewedPropertiesLoading', false);
        }
    },

    async getSavedProperties({ commit }, payload = {}) {
        try {
            commit('setSavedPropertiesLoading', true);
            const res = await fetchSavedProperties(payload);
            if (res.data) {
                commit('setSavedProperties', res.data.data);
            }
        } catch (error) {
            throw error;
        } finally {
            commit('setSavedPropertiesLoading', false);
        }
    },
    async contactAgent({ commit }, payload = {}) {
        try {
            return await contactAgent(payload);
        } catch (error) {
            throw error;
        }
    },
    setUser({ commit }, value) {
        if (value && value.api_token) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + value.api_token;
        }
        commit('setUser', value);
    },

    setLoading({ commit }, value) {
        commit('setLoading', value);
    },
    setLoggedIn({ commit }, value) {
        commit('setLoggedIn', value);
    },
    setColorMode({ commit }, value) {
        commit('setColorMode', value);
    },
    setGeoIPCity({ commit }, value) {
        commit('setGeoIPCity', value);
    },
    removeSavedListing({ commit }, listingKey) {
        commit('removeListingFromSavedList', listingKey);
    },
    removeSavedSearch({ commit }, searchKey) {
        commit('removeSearchFromSavedSearches', searchKey);
    },
    setPropertyDownPaymentResult({ commit }, value) {
        commit('setPropertyDownPaymentResult', value);
    },
    setDemoAgent({ commit }, value) {
        commit('setDemoAgentData', value);
    },
    setDemoLender({ commit }, value) {
        commit('setDemoLenderData', value);
    },
    setUnAuthActions({ commit, state }, value) {
        let actions = [JSON.parse(JSON.stringify(state.unAuthActions))];
        // check if action already exists
        // then overwrite it
        let index = actions.findIndex((action) => action.name === value.name);
        if (index > -1) {
            actions[index] = value;
        } else {
            actions.push(value);
        }

        commit('setUnAuthActions', actions);
    },
    clearUnAuthAction({ commit, state }, actionIndex) {
        let actions = [JSON.parse(JSON.stringify(state.unAuthActions))];
        actions.splice(actionIndex, 1);
        commit('setUnAuthActions', actions);
    }
};
