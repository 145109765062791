export default {
    branding_id: (state) => state.brandingInfo.branding_id,
    branding_group_id: (state) => state.brandingInfo.branding_group_id,
    branding_location_id: (state) => state.brandingInfo.branding_location_id,
    // initial_group_id: state => state.brandingInfo.initial_group_id,
    // initial_branding_id: state => state.brandingInfo.initial_branding_id,
    // initial_location_id: state => state.brandingInfo.initial_location_id,
    viewed: (state) => state.viewed,
    brandingInfo: (state) => state.brandingInfo,
    entranceData: (state) => state.entranceData,
    regConfig: (state) => state.entranceData.regConfig,
    propertyParams: (state) => state.propertyParams,
    currentData: (state) => state.currentData,
    locationData: (state) => state.locationData,
    locationDistance: (state) => state.locationDistance,
    forced: (state) => state.forced,
    forcedExists: (state) => state.forcedExists,
    propertyViewCount: (state) => state.viewed.length,
    isRegistered: (state) => state.isRegistered,
    isProgressive: (state) => state.isProgressive,
    isExtendedReg: (state) => state.isExtendedReg,
    isRentalFulfillment: (state) => state.isRentalFulfillment,
};
