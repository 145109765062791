export default {
  user: (state) => state.user,
  isLoading: (state) => state.isLoading,
  isLoggedIn: (state) => state.isLoggedIn,
  isSavedSearchLoading: (state) => state.isSavedSearchLoading,
  savedSearches: (state) => state.savedSearches,
  isViewedPropertiesLoading: (state) => state.isViewedPropertiesLoading,
  viewedProperties: (state) => state.viewedProperties,
  savedProperties: (state) => state.savedProperties,
  colorMode: (state) => state.colorMode,
  geoIPCity: (state) => state.geoIPCity,
  propertyDownPaymentResults: (state) => state.propertyDownPaymentResults,
  demoAgentData: (state) => state.demoAgentData,
  demoLenderData: (state) => state.demoLenderData,
  isSalesUser: (state) => state.user?.preferences?.sales === true,
  unAuthActions: (state) => state.unAuthActions,
};
