import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = () => ({
    user: {},
    isLoading: false,
    isLoggedIn: false,
    isSavedSearchLoading: false,
    savedSearches: [],
    isViewedPropertiesLoading: false,
    viewedProperties: [],
    isSavedPropertiesLoading: false,
    savedProperties: [],
    colorMode: null,
    geoIPCity:{},
    propertyDownPaymentResults: [],
    demoAgentData: {},
    demoLenderData: {},
    unAuthActions: [],
});

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
