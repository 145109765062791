import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = () => ({
    brandingInfo: {
        branding_id: null,
        branding_group_id: null,
        branding_location_id: null
        // initial_group_id: null,
        // initial_branding_id: null,
        // initial_location_id: null,
    },

    entranceData: {
        groupLocationId: null,
        location: {},
        brand: {},
        regConfig: {},
        timeStamp: null,
        utmParams: {},
        propertyParams: {}
    },
    currentData: {
        groupLocationId: null,
        location: {},
        brand: {},
        regConfig: {},
        timeStamp: null,
        utmParams: {},
        propertyParams: {}
    },
    locationData: {},
    viewed: [],
    locationDistance: null,
    forced: false,
    forcedExists: false,
    isRegistered: false,
    isProgressive: false,
    isExtendedReg: {},
    isRentalFulfillment: false,
    propertyParams: {},
});

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
